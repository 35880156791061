@import "../../../../../style/configs/breakpoints.css";
.reset-pass-container{
    height: 100vh;
    background-image: url("../../../../../assets/img/fondo-login.png") ;
    /* background-color: #55c4d4; */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /*
    /*background: linear-gradient(to right, #0062E6, #3ac1ff);*/
}
