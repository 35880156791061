.centrado-porcentual {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -80%);
    font-size: 3rem;
    font-weight: bold;
    -webkit-transform: translate(-50%, -80%)
}

.centrado-porcentual .letra {
    text-align: center;

    background: linear-gradient(to left, #035192 0%, #55c4d4 50%, #035192 100%);
    background-size: 200% auto;

    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    animation: color-change 1.2s linear infinite;
  }
  @keyframes color-change {
    from {
      background-position: 200% center;
    }
  }

.fondo-splash{
    height: 100vh;
    background-image: url("../../../../../assets/img/back_cargando.png") ;
    /* background-color: #55c4d4; */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
}
